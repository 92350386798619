class ZIDXAccountWidgets implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountWidgetsContainer";
    }
    getMatchURL(){
        return "/account/widgets";
    }
    render(){
        // console.log("widgets");
    }
}